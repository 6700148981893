import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import Aux from "../../global/AuxComp";
import InputFeild from "../../global/form/input";
import {setError, setLoader } from '../../../actions/action-creators';
import store from '../../../store';
import { Chip, Icon, Switch, Tab, TablePagination, Tabs } from "@material-ui/core";
import SelectList from "../../global/form/select";
import Table from "reactstrap/lib/Table";
import moment from "moment";
import CONSTANTS from "../../../constants";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import DatePicker from "react-datepicker";
import Button from "reactstrap/lib/Button";
import { accessExotelCallRecording, downloadApiFile } from "../../../actions";
import { Collapse } from "reactstrap";
import UserCard from "../user_profile/user_card";
import CustomerCard from "../leads/customer_card";

class CompanyCreate extends Component {
    constructor(props) {
      super(props);
      this.audioTags = [];
      this.state = {
          jobList : [],
          callLogs: [],
          callersList: [],
          selectedCallerUserId: null,
          selectedJobId: null,
          rowsPerPage : 20,
          currentUserPage : 0,
          callSearchText: "",
          isOpenIncomingCall : false,
          selectedTab: 0,
          isShowOnlyCompletedCalls: true,
          isFilters: false,
          callLogCounts:0
        }
    }

    async componentDidMount(){
      await this.getJob();
      await this.fetchCallers();
    }
    
    selectTab = async (i) => {
      await this.setState({ selectedTab: i });
      this.fetchCallLogs();
    }

    fetchCallers = async () => {
      try{
          let body = { role_id: 10 };
          let resp = await ApiEndpoints.User.fetchRoleMappedUser(body);
          this.setState({ callersList: resp.data.data })
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
  }

    getJob = async () => {
      store.dispatch(setLoader(true))
      try{
          let apiData = await ApiEndpoints.Jobs.fetch();
          var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1: -1);
          jobList = jobList
          .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
          .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
          await this.setState({ jobList });
          if(this.props.match.params.job_id){
            if(this.state.jobList.filter(job => job.job_id == this.props.match.params.job_id).length){
              this.setJobId(this.props.match.params.job_id)
            }
          }
          else if(this.state.jobList.length)  this.setJobId(this.state.jobList[0].job_id)
          
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }
    setJobId  = async (job_id) => {
      let { jobList } = this.state;
      store.dispatch(setLoader(true))
      
      var selectedJob = jobList.filter(obj => obj.job_id == job_id)[0];
      await this.setState({selectedJob,selectedJobId : job_id, selectedTask : null, teamLeaders: [], selectedTlId: null})
            
      await this.fetchCallLogs();
      store.dispatch(setLoader(false))
    }
    fetchCallLogs = async (page = 0) => {
      store.dispatch(setLoader(true));
      let { selectedTab, isShowOnlyCompletedCalls, isFilters, selectedJobId } = this.state;
      try{
        var body = { 
          limit: 20,
          offset: 20 * page
        };
        if(isShowOnlyCompletedCalls) body.status = "completed";
        if(selectedTab === 1) body.entity_type = "NEW_USER";
        else {
          if(!selectedJobId) throw new Error("Please select project");
          body.entity_type = "JOB";
          body.entity_id = selectedJobId;
        }
        if(this.state.selectedCallerUserId) body = {...body, user_id: this.state.selectedCallerUserId}
        if(isFilters.direction) body = {...body, direction: isFilters.direction}
        if(isFilters.start_date) body = {...body, start_date: moment(isFilters.start_date).startOf("day").toDate()}
        if(isFilters.end_date) body = {...body, end_date: moment(isFilters.end_date).endOf("day").toDate()}
        let apiData = await ApiEndpoints.Calling.fetchDetails(body);
        var data = apiData.data.data.rows || [];
        var callLogCounts = apiData.data.data.count || 0
        this.setState({ callLogs: data, callLogCounts, currentUserPage: page});
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    filterCallLogsByCaller = async (userId) => {
      await this.setState({ selectedCallerUserId: userId });
      this.fetchCallLogs();
    }

    downloadTask = async (opts = {}) => {
      store.dispatch(setLoader(true));
      let { start_date, end_date } = opts;
      try {
          var body = {download: true};
          if(start_date) body.start_date = moment(start_date).startOf("day").toDate();
          if(end_date) body.end_date = moment(end_date).endOf("day").toDate();
          var resp1 = await ApiEndpoints.Calling.fetchIncomingCalls(body);
          downloadApiFile(resp1, "Task Response.csv")
      }
      catch(e){
          console.log(e.response)
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    toggleFilter = async () => {
      let { isFilters } = this.state;
      if(isFilters) {
          await this.setState({isFilters: false});
          this.selectTab(this.state.selectedTab);
      }
      else this.setState({ isFilters: {} })
    }

    render() {
      let { selectedTab, isShowOnlyCompletedCalls, jobList, callLogs, callSearchText, isOpenIncomingCall, isFilters, callersList } = this.state;
      if(callSearchText){
        callLogs = callLogs.filter(call => {
          if(call.toDetail.first_name && call.toDetail.first_name.toLowerCase().includes(callSearchText.toLowerCase())) return true;
          if(call.toDetail.id && call.toDetail.id.toString().includes(callSearchText.toLowerCase().replace("pmw", ""))) return true;
          if(call.fromDetail.first_name && call.fromDetail.first_name.toLowerCase().includes(callSearchText.toLowerCase())) return true;
          return false;
        });
      }
      this.audioTags.map(ref => ref && ref.load());
        return (
          <Aux>
            <Row className="border-bottom pb-0 mb-2 align-items-center">
                <Col>
                    <Tabs  variant="standard" value={selectedTab} onChange={(e, i) => this.selectTab(i)} aria-label="call logs tabs">
                        <Tab  label="Project Calls"  id='simple-tab-1' aria-controls='simple-tabpanel-1' />
                        {/* <Tab  label="New User Calls" id='simple-tab-2' aria-controls='simple-tabpanel-2' /> */}
                    </Tabs>
                </Col>
                <Col xs={"auto"}>
                  <Switch size="small" checked={isShowOnlyCompletedCalls} onChange={async (e) => { await this.setState({ isShowOnlyCompletedCalls: e.target.checked }); this.selectTab(selectedTab); }} /> <span style={{fontSize: 12}}>Only Success Calls</span>
                </Col>
                {/* <Col xs={"auto"} className="text-right">
                  <Button onClick={() => this.setState({isOpenIncomingCall : {}})} className="buttonred">Missed Incoming Calls</Button>
                </Col> */}
            </Row>
            {selectedTab === 0 && <Row>
                <Col xs={6}>
                  
                     <SelectList search={true} placeholder="Select Project" value={this.state.selectedJobId} onChange ={(e) => this.setJobId(e.target.value)} >
                      { 
                      jobList.map(job => 
                      <option key={job.job_id} value={job.job_id}> 
                        {job.Employer.employer_name +  "  -  "  + job.title} 
                        {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                      </option>
                      )
                      }
                  </SelectList>
                </Col>
            </Row>}
            <Row className="mt-4 ">
              <Col>
              <Row className="border-bottom">
                <Col xs={8}>
                  <h5>Call Details</h5>
                </Col>
                <Col>
                  <SelectList selectAll="All Callers" className="mt-2" placeholder="Caller Filter" value={this.state.selectedCallerUserId || ""} onChange ={(e) => this.filterCallLogsByCaller(e.target.value)} >
                    {callersList.map(caller => 
                        <option key={caller.user_role_id} value={caller.user_id}>{caller.User.first_name}</option>
                    )}
                  </SelectList>
                  {/* <InputFeild onChange={(e) => this.setState({callSearchText: e.target.value})} icon="search" placeholder="Search by name" /> */}
                </Col>
                <Col xs="auto">
                    <Icon className="align-middle mr-3 " style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.toggleFilter()}>{isFilters ? "filter_alt_off" : "filter_alt"}</Icon>
                </Col>
              </Row> 
              <Collapse isOpen={!!isFilters}>
                    <Row className="justify-content-end">
                        <Col xs={12} lg={6} className="border mx-3 mb-3">
                            <SelectList className="mt-2" placeholder="Select Call Type" value={isFilters.direction || ""} onChange ={(e) => {isFilters.direction = e.target.value; this.setState({isFilters})}} >
                            {[{direction: 1, label: "Incoming"}, {direction:  2, label: "Outgoing"}].map(obj => 
                                <option key={obj.direction} value={obj.direction}>{obj.label}</option>
                            )}
                            </SelectList>
                            <Row className={"mt-3"}>
                                <Col>
                                    <DatePicker
                                        selected={isFilters.start_date || null}
                                        onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                                        isClearable
                                        dateFormat='dd-MMM-yyyy'
                                        placeholderText="Start Date"
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={isFilters.end_date || null}
                                        onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                                        isClearable
                                        dateFormat='dd-MMM-yyyy'
                                        placeholderText="End Date"
                                    />
                                </Col>
                            </Row>
                            <div className="text-right py-3"><Button color="dark" size="sm" onClick={() => this.selectTab(selectedTab)}>Apply Filter</Button></div>
                        </Col>
                    </Row>
                </Collapse>
              <Table bordered className="table-sm mt-4 mb-0 text-center">
                                <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Partner</th>
                                      <th>Manager</th>
                                      <th>Direction</th>
                                      <th>Recording</th>
                                      <th>Comment</th>
                                      <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {callLogs.length ? callLogs.map((call, i) => { 
                                    var partner = call.direction === 1 ? call.fromDetail || {} : call.toDetail || {};
                                    var manager = call.direction === 1 ? call.toDetail || {} : call.fromDetail || {};
                                    return <tr>
                                      <td>{this.state.currentUserPage*this.state.rowsPerPage+i+1}</td>
                                      <td>
                                        {call.subentity_type === "CUSTOMER_LEAD" ? <CustomerCard data={partner} /> : <UserCard data={partner} />}
                                      </td>
                                      <td>{manager.first_name}</td>
                                      <td><span className="d-block">{call.direction === 1 ? "In" : "Out"}</span>{call.duration && <strong style={{fontSize: 12}}>{call.duration} Sec</strong>}</td>
                                      <td>
                                        {call.call_recording_url ?
                                          <audio style={{maxWidth: 185}} ref={(ref) => this.audioTags[i] = ref} preload={true} controls><source src={accessExotelCallRecording(call.call_recording_url)} type="audio/ogg" /></audio>
                                          :
                                          <strong>{call.status}</strong>
                                        }
                                      </td> 
                                      <td>{call.comment}</td>
                                      <td>
                                        {moment(call.created_on).utcOffset("+05:30").format("hh:mmA Do MMM'YY")}
                                        <em className="d-block" style={{fontSize: 12}}><strong>{CONSTANTS.APPLICATION_STATUS_TEXT[call.subentity_id] || ""}</strong></em>
                                      </td>
                                    </tr>}
                                  ) : 
                                  <tr className="text-center py-3"><td colSpan={6}><em>No Call Records found</em></td></tr>
                                  }
                                </tbody>
                            </Table>  
                            <TablePagination rowsPerPageOptions={[20]} component="div" count={this.state.callLogCounts} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                            // onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                            onChangePage={ (e, page) => this.fetchCallLogs(page) }
                            onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                        />
              </Col>
            </Row>
            <Modal isOpen={!!isOpenIncomingCall} toggle={()=> {this.setState({isOpenIncomingCall : false})}} >
                <ModalHeader toggle={()=> {this.setState({isOpenIncomingCall : false})}}>Download Incoming Calls</ModalHeader>
                <ModalBody className="pb-5 pl-5 pr-5 pb-3">
                <Row className="border-top mt-4">
                        <Col>
                            <DatePicker
                                selected={isOpenIncomingCall ? isOpenIncomingCall.start_date : null}
                                onChange={date => {isOpenIncomingCall.start_date = date; this.setState({isOpenIncomingCall})}}
                                className="mt-3"
                                dateFormat='dd-MMM-yyyy'
                                id="download_task_resp_input1"
                                placeholderText="Start Date-"
                            />
                        </Col>
                        <Col>
                            <DatePicker
                                selected={isOpenIncomingCall ? isOpenIncomingCall.end_date : null}
                                onChange={date => {isOpenIncomingCall.end_date = date; this.setState({isOpenIncomingCall})}}
                                className="mt-3"
                                dateFormat='dd-MMM-yyyy'
                                id="download_task_resp_input2"
                                placeholderText="End Date"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-4">
                            <Button id="download_task_resp_submit" size="sm" onClick={() => this.downloadTask(isOpenIncomingCall)} color="dark">Download</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
          </Aux>
        );
    }
}

export default CompanyCreate;
