import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";
import SelectList from "../../global/form/select";
import { Chip, Icon, TablePagination } from "@material-ui/core";
import CONSTANTS from "../../../constants";
import { accessExotelCallRecording, groupArrayOnKey } from "../../../actions";
import OutboundCallingStatus from "../../global/outbound-calling-status";
import ConfirmAction from "../../global/confirm-action";

class UserCallDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callData : [],
            selectedJob: null,
            jobList: [],
            currentPage: 0,
            callDetails: null,
            selectedstatus: null,
            currentstatus: null
        }
    }

    componentDidMount(){
        this.getJob();
        this.fetchCallData();
    }


    getJob = async () => {
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
            await this.setState({ jobList });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchCallData = async (selectedJob) => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            if(selectedJob){
                body.entity_type = ["JOB", "JOB_HELP"];
                body.entity_id = selectedJob
            }
            let resp = await ApiEndpoints.Calling.fetchDetailsByUser(body)
            let callData = resp.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on) )
            
            this.setState({ callData, selectedJob, currentPage: 0 })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }



     makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "GENERAL",
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }
    
    render() {
        let { callData, selectedJob, jobList,selectedstatus} = this.state;
        let { userInfo } = this.props;
        let jobListById = groupArrayOnKey(jobList, "job_id", true);
        let callsRecord = [...new Set(callData.map((value)=>value.status))]
        let user = store.getState().user;
        return (
            <div className="p-1">
                <Row>
                    <Col>
                        {!CONSTANTS.OUTBOUND_BLOCKED_USERS.includes(user.user_id) && <Button className="buttonred py-0" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(userInfo), isConfirmMessage : "Are you sure to connect with "+ userInfo.first_name+"?"})}>Make Call</Button>}
                    </Col>
                       
                       <Col>
                            <SelectList id="project_type" required placeholder="All Status" 
                                value={selectedstatus} onClick={(e) => {
                                        this.setState({selectedstatus:e.target.value})
                                    }
                                        
                                        }>
                                    {
                                        callsRecord.map((call,i) =><option key={i} value={call}> {call} 
                                        </option>)
                                    }             
                            </SelectList>
                     </Col>
                    <Col>
                        <SelectList id="project_type" required placeholder="All Project Calls" value={selectedJob}  onChange={(e) => this.fetchCallData(e.target.value)}>
                                { jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                                    {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                </option>)}
                        </SelectList>
                    </Col>
                </Row>
                {callData.length ? 
                <>
                
                {callData
                .slice(this.state.currentPage*10, this.state.currentPage*10 + 10)
                 .filter(call => selectedstatus != null ? selectedstatus === call.status : call)
                    .map(call =>                    
                    {
                             return(
                        <Row style={{fontSize: 14, borderRadius: 12}} className="bg-light border align-items-center py-1 my-2">
                            <Col xs={6}>
                                <strong className="d-block">{call.userDetail && call.userDetail.first_name}</strong>
                                <span className="small d-block">{call.direction === 1 ? "Incoming Call" : "Outgiong Call"}</span>
                                <span className="small d-block" style={{fontWeight: 600}}><Icon className="align-middle" style={{fontSize:12}}>comment</Icon>{call.comment || " - "}</span>
                            </Col>
                            <Col xs={6} className="text-right">
                                <strong className="d-block"> {call.feedback_score ? <span>&#9733; { call.feedback_score } | </span> : ""} {call.status}</strong>
                                { ["JOB", "JOB_HELP"].includes(call.entity_type) && <span className="d-block" style={{fontSize: 12}}>Project: { jobListById[call.entity_id] ? jobListById[call.entity_id].Employer.employer_name + "-"+jobListById[call.entity_id].title : "Not Found" }</span> }
                                <span className="px-2 small">{moment(call.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                            </Col>
                            {call.call_recording_url && <Col xs={12} className="mt-2" >
                                <audio style={{width: "100%", height: 40}} controls={true} src={accessExotelCallRecording(call.call_recording_url)} />
                            </Col>}
                        </Row>)
                        }
                )
            
                }
                 <TablePagination rowsPerPageOptions={[15]} component="div" count={callData.length} rowsPerPage={10} page={this.state.currentPage}
                    onChangePage={ (e, page) => this.setState({currentPage : page}) }
                /> 
                </>
                : <em className="m-4 text-secondary d-block">No Calls data</em> }
                     
                <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

                {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}

            </div>
           
        );
    }
}
export default UserCallDetails;
