import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Icon, TablePagination } from "@material-ui/core";
import moment from "moment";
import { accessExotelCallRecording, groupArrayOnKey } from "../../../actions";


class CustomerCallDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList : [],
            jobList: store.getState().jobList,
            currentPage: 0
        }
    }
   
    componentDidMount(){
        this.fetchCustomerCallDetails();
    }

    fetchCustomerCallDetails = async () => {
        try{
           let resp = await ApiEndpoints.Customer.customerCallingDetails({customer_lead_id: this.props.customer_lead_id});
           let detailList = resp.data.data;
           this.setState({detailList , currentPage: 0})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    render() {
        let { detailList, jobList } = this.state;
        let jobListById = groupArrayOnKey(jobList, "job_id", true);

        return (
            <div>
            {detailList.length ?
            <>
                {detailList
                .slice(this.state.currentPage*10, this.state.currentPage*10 + 10)
                .map(list =>
                        
                <Row style={{fontSize: 14, borderRadius: 12}} className="bg-light border align-items-center py-1 my-2">
                    <Col xs={6}>
                        <strong className="d-block">{list.userDetail && list.userDetail.first_name}</strong>
                        <span className="small d-block">{list.direction === 1 ? "Incoming Call" : "Outgiong Call"}</span>
                        <span className="small d-block" style={{fontWeight: 600}}><Icon className="align-middle" style={{fontSize:12}}>comment</Icon>{list.comment || " - "}</span>
                    </Col>
                    <Col xs={6} className="text-right">
                        <strong className="d-block"> {list.feedback_score ? <span>&#9733; { list.feedback_score } | </span> : ""} {list.status}</strong>
                        { ["JOB", "JOB_HELP"].includes(list.entity_type) && <span className="d-block" style={{fontSize: 12}}>Project: { jobListById[list.entity_id] ? jobListById[list.entity_id].Employer.employer_name + "-"+jobListById[list.entity_id].title : "Not Found" }</span> }
                        <span className="px-2 small">{moment(list.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                    </Col>
                    {list.call_recording_url && <Col xs={12} className="mt-2" >
                        <audio style={{width: "100%", height: 40}} controls={true} src={accessExotelCallRecording(list.call_recording_url)} />
                    </Col>}
                </Row>
                
                )}
            
            
                <TablePagination rowsPerPageOptions={[15]} component="div" count={detailList.length} rowsPerPage={10} page={this.state.currentPage}
                    onChangePage={ (e, page) => this.setState({currentPage : page}) }
                /> 
                </>
                : <em className="m-4 text-secondary d-block">No Calls data</em> }
 
            
            </div>
            
        );
    }
}
export default CustomerCallDetails;
