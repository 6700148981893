import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";
import { Chip, Icon, TablePagination } from "@material-ui/core";
import CONSTANTS from "../../../constants";
import { accessExotelCallRecording, groupArrayOnKey } from "../../../actions";
// import OutboundCallingStatus from "../../global/outbound-calling-status";
// import ConfirmAction from "../../global/confirm-action";

class UserCallDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callData : [],
            selectedJob: null,
            jobList: [],
            currentPage: 0,
            callDetails: null,
            selectedstatus: null,
            currentstatus: null
        }
    }

    componentDidMount(){
        this.fetchCallData();
    }


   
    fetchCallData = async (selectedJob) => {
        store.dispatch(setLoader(true))
        try{
            var body = { customer_lead_id : this.props.customer_lead_id }
            // if(selectedJob){
            //     customer_lead_id: 
            // }
            let resp = await ApiEndpoints.Calling.fetchCustomerCallDetails(body)
            let callData = resp.data.data.rows.sort((a, b) => new Date(b.created_on) - new Date(a.created_on) )
            
            this.setState({ callData, currentPage: 0 })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }



    render() {
        let { callData,selectedstatus} = this.state;
        let jobList = store.getState().jobList;
        let jobListById = groupArrayOnKey(jobList, "job_id", true);
        let user = store.getState().user;
        return (
            <div className="p-1">
                {callData.length ? 
                <>
                
                {callData
                .slice(this.state.currentPage*10, this.state.currentPage*10 + 10)
                 .filter(call => selectedstatus != null ? selectedstatus === call.status : call)
                    .map(call =>                    
                    {
                             return(
                        <Row style={{fontSize: 14, borderRadius: 12}} className="bg-light border align-items-center py-1 my-2">
                            <Col xs={6}>
                                <strong className="d-block">{call.agentDetail && call.agentDetail.first_name}</strong>
                                <span className="small d-block">{call.direction === 1 ? "Incoming Call" : "Outgoing Call"}</span>
                                <span className="small d-block" style={{fontWeight: 600}}><Icon className="align-middle" style={{fontSize:12}}>comment</Icon>{call.comment || " - "}</span>
                            </Col>
                            <Col xs={6} className="text-right">
                                <strong className="d-block"> {call.feedback_score ? <span>&#9733; { call.feedback_score } | </span> : ""} {call.status}</strong>
                                { ["JOB", "JOB_HELP"].includes(call.entity_type) && <span className="d-block" style={{fontSize: 12}}>Project: { jobListById[call.entity_id] ? jobListById[call.entity_id].Employer.employer_name + "-"+jobListById[call.entity_id].title : "Not Found" }</span> }
                                <span className="px-2 small">{moment(call.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                            </Col>
                            {call.call_recording_url && <Col xs={12} className="mt-2" >
                                <audio style={{width: "100%", height: 40}} controls={true} src={accessExotelCallRecording(call.call_recording_url)} />
                            </Col>}
                        </Row>)
                        }
                )
            
                }
                 <TablePagination rowsPerPageOptions={[15]} component="div" count={callData.length} rowsPerPage={10} page={this.state.currentPage}
                    onChangePage={ (e, page) => this.setState({currentPage : page}) }
                /> 
                </>
                : <em className="m-4 text-secondary d-block">No Calls data</em> }

            </div>
           
        );
    }
}
export default UserCallDetails;
